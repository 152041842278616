import { VERSION } from './app-variables';

export const MONKIA_CONFIG = {
  dsn: 'https://643a6fce7662a78495e1d96c91d5218d@o4505669501648896.ingest.sentry.io/4505673903505408',
  environment: VERSION,
  debug: VERSION !== 'prod',
  tracesSampleRate: 0.025,
  tracingOrigins: [
    'localhost',
    'evaluation.hgregoire.com',
    'main-client.prod.evaluation.hgregoire.com',
    'main-client.stage.evaluation.hgregoire.com',
    'staging-evaluation.hgregoire.com',
  ],
};

export const initialColors = {
  accent: '#ff9800',
  background: '#000',
  disabled: 'gray',
  text: '#ff',
  error: '#fa603d',
  placeholder: 'gray',
  actions: {
    primary: { background: '#000', text: '#fff' },
    secondary: { background: '#000', text: '#fff', disabled: '#' },
  },
};

export const IS_MONKAI_AVAILABLE = true;
