import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MonitoringProvider } from '@monkvision/corejs';
import sightsData from '@monkvision/sights';

import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { Alert, Box, Chip, Divider, Grid, Typography } from '@mui/material';
import useImageOfflineQueue from 'hooks/useImageOfflineQueue';
import ImageCard from '../../../physical-evaluation/components/image-card';
import makeStyles from '@mui/styles/makeStyles';
import CameraWebNew, {
  TakePictureArg,
} from 'modules/physical-evaluation/components/CameraWebNew';
import { isNil } from 'lodash';
import {
  IS_MONKAI_AVAILABLE,
  MONKIA_CONFIG,
} from 'constants/config/monkia.config';
import { GenericSight, sights } from '../../constants/values';
import CheckBoxController from 'ui/inputs/controllers/CheckBox.controller';
import { PePicturesForm } from 'constants/forms';
import useMonkToken from './hooks/useMonkToken';
import useStore from 'store/pe-edit-appraisal.store';
import { useInspectionsStore } from '../../../../store/offline-changes/inspections/inspections.store';
import { validate } from 'uuid';
import { CAR_IMAGE_PLACEHOLDER } from '../../../../constants/entities/static';
import useUploadInspectionImage from '../../../../hooks/offline/useUploadInspectionImage';

const useStyles = makeStyles(() => ({
  certify: {
    fontSize: '18px',
    fontWeight: 500,
    color: '#f86c6b',
  },
}));

const PicturesForm: React.FC = () => {
  const { appraisal } = useStore();
  const { photos = [], id = 0, monkaiInspectionId } = appraisal ?? {};
  const { t } = useTranslation();
  const { jobs, addImage } = useImageOfflineQueue();
  const { getPhotoSrc } = useInspectionsStore();

  const classes = useStyles();

  const {
    loading: isTokenLoading,
    error: monkaiError,
    onError,
    onLoading,
  } = useMonkToken();

  const [isCameraEnabled, setIsCameraEnabled] = useState(false);
  const [sightIds, setSightIds] = useState<string[]>(Object.keys(sights));
  const { uploadInspectionImage } = useUploadInspectionImage();

  const onTakePicture = async (e: TakePictureArg) => {
    try {
      if (e.sight === 'generic') {
        return uploadInspectionImage({
          variables: {
            id,
            file: {
              id,
              zone: 'generic',
              src: e.picture,
            },
          },
        });
      }

      return uploadInspectionImage({
        variables: {
          id,
          file: {
            id,
            zone: sights[e.sight],
            src: e.picture,
          },
        },
      });
    } catch (error) {
      console.log('onTakePicture error: ', error);
    }
  };

  const findAlredyExists = () => {
    const alreadyExists = photos.map((it) => it.zone);

    const emptySightIds = [];
    const presentPicture = [];

    for (const [key, value] of Object.entries(sights)) {
      if (!alreadyExists.includes(value)) {
        emptySightIds.push(key);
      } else {
        presentPicture.push(key);
      }
    }

    if (presentPicture.length < Object.keys(sights).length) {
      setSightIds(emptySightIds);
      return;
    }

    setSightIds(Object.keys(sights));
  };

  const [editPicture, setEditPicture] = useState(false);
  const [editOneKey, setEditOneKey] = useState<string[]>([]);

  const takeOnePicture = (zona: string) => {
    setEditPicture(true);

    const imageKey = findIdPecture(zona);

    if (isNil(imageKey)) {
      setEditPicture(false);
      return;
    }

    setEditOneKey([imageKey]);
  };

  const findIdPecture = (zona: string) => {
    for (const [key, value] of Object.entries(sights)) {
      if (value === zona) {
        return key;
      }
    }
  };

  const onStartCamera = async () => {
    if (isTokenLoading) {
      return onLoading();
    }

    if (monkaiError) {
      return onError();
    }

    findAlredyExists();
    setIsCameraEnabled(true);
  };

  const onStopCamera = () => {
    setIsCameraEnabled(false);
    setEditPicture(false);
  };

  const renderGenericPhotos = useCallback(() => {
    const pendings = jobs.filter(
      (it) => it.zone === GenericSight && it.id === id
    );

    const generics = photos.filter(
      (it) => it.zone === GenericSight && it.id === id
    );

    const all = [...pendings, ...generics];

    return all.map((it, idx) => (
      <Grid
        key={`${GenericSight}-${idx}`}
        item
        container
        xs={12}
        sm={6}
        md={4}
        lg={3}
        className="cursor-pointer"
        justifyContent="center"
      >
        <ImageCard
          isCanEdit
          id={`file-${GenericSight}-${idx}`}
          label={t(`pictures.${GenericSight}`)}
          zone={GenericSight}
          src={
            validate(it.src)
              ? getPhotoSrc(it.src) ?? CAR_IMAGE_PLACEHOLDER
              : it.src
          }
        />
      </Grid>
    ));
  }, [jobs, photos]);

  const renderPhoto = useCallback(
    (sight: string, zone: string) => {
      const pending = jobs.find((it) => it.zone === zone && it.id === id);

      const photo = photos.find((it) => it.zone === zone && it.id === id);
      const photoSrc = photo
        ? validate(photo.src)
          ? { src: getPhotoSrc(photo.src) ?? CAR_IMAGE_PLACEHOLDER }
          : { src: photo.src }
        : null;

      const base64 = btoa(
        unescape(encodeURIComponent((sightsData as any)[sight]?.overlay))
      );

      const image = pending
        ? {
            ...pending,
            src: validate(pending.src)
              ? getPhotoSrc(pending.src) ?? CAR_IMAGE_PLACEHOLDER
              : pending.src,
          }
        : photoSrc || { src: `data:image/svg+xml;base64,${base64}` };

      return (
        <Grid
          key={`${zone}`}
          item
          container
          xs={12}
          sm={6}
          md={4}
          lg={3}
          className="cursor-pointer"
          justifyContent="center"
        >
          <ImageCard
            isCanEdit
            onEdite={() => takeOnePicture(zone)}
            id={`file-${zone}`}
            label={t(`pictures.${zone}`)}
            zone={zone}
            isUploading={!!pending}
            src={image.src}
          />
        </Grid>
      );
    },
    [jobs, photos]
  );

  return (
    <Box data-cy-photos-form pt={2}>
      {!IS_MONKAI_AVAILABLE && (
        <Alert severity="warning" sx={{ mb: 4, maxWidth: 800, mx: 'auto' }}>
          <Typography variant="h6">
            Monkai is currently unavailable. This means that no inspections will
            be performed at this time. However, all images will still be
            captured and saved as usual. Once Monkai is back online, inspections
            will resume automatically.
          </Typography>
        </Alert>
      )}

      <MonitoringProvider config={MONKIA_CONFIG}>
        {editPicture && (
          <Box>
            <CameraWebNew
              inspectionId={monkaiInspectionId!}
              sightIds={editOneKey}
              onTakePicture={onTakePicture}
              onClose={onStopCamera}
            />
          </Box>
        )}

        {isCameraEnabled && (
          <Box>
            <CameraWebNew
              inspectionId={monkaiInspectionId!}
              sightIds={sightIds}
              onTakePicture={onTakePicture}
              onClose={onStopCamera}
            />
          </Box>
        )}

        {!isCameraEnabled && (
          <Grid justifyContent="center" item xs={12}>
            <Box onClick={onStartCamera} mb={4} data-cy="open-camera">
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                overflow="hidden"
                className="cursor-pointer"
              >
                <Box
                  display="flex"
                  bgcolor="action.disabled"
                  color="white"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="8px"
                  height={196}
                  width={256}
                >
                  <CameraAltIcon style={{ fontSize: 64 }} />
                </Box>
                <Box mt={-1.5} zIndex={1}>
                  <Chip
                    className="uppercase"
                    color="primary"
                    label={t(`pictures.camera`)}
                  />
                </Box>
              </Box>
            </Box>
          </Grid>
        )}

        <Grid container spacing={2}>
          {Object.keys(sights).map((key) => renderPhoto(key, sights[key]))}
          {renderGenericPhotos()}
        </Grid>

        <Box my={4}>
          <Divider />
        </Box>

        <Grid container spacing={2}>
          <Box mx={2} mb={4} className={classes.certify}>
            <CheckBoxController
              name={PePicturesForm.Keys.PhotosCertify}
              label={'pictures.photos-certify'}
              inputProps={
                {
                  ['data-cy']: 'certify-photos',
                } as any
              }
            />
          </Box>
        </Grid>
      </MonitoringProvider>
    </Box>
  );
};

export default PicturesForm;
