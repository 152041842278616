import { TakePictureArg } from 'modules/physical-evaluation/components/CameraWebNew';
import { useUploadImageAbility } from 'contexts/OnlineStatusProvider/OnlineStatusProvider';
import { IS_MONKAI_AVAILABLE } from '../../../../../constants/config/monkia.config';

type Options = {
  sightIds: string[];
  sights: any[];
  uploads: any[];
  onPicture: (arg: TakePictureArg) => Promise<void>;
};

const useAddPictureHandler = ({ onPicture }: Options) => {
  const isAbleUploadImage = useUploadImageAbility();

  const handlePress = async (state: any, api: any) => {
    try {
      const pictureResult = await api.takePictureAsync();

      api.setPictureAsync(pictureResult);

      if (isAbleUploadImage && IS_MONKAI_AVAILABLE) {
        api.startUploadAsync(pictureResult);
      }

      onPicture({
        picture: pictureResult,
        sight: state.sights.state.current.id,
      });
    } finally {
      api.goNextSight();
    }
  };

  return { handlePress };
};

export default useAddPictureHandler;
