import {
  useGetAppraisalMutation,
  useStartMonkaiInspectionMutation,
  useUpdateAppraisalMutation,
  useUploadAppraisalPhotoReactiveMutation,
} from 'graphql-types';
import { InspectionItem } from './types';
import { useInspectionsStore } from './inspections.store';
import { sights } from 'modules/physical-eval-edit/constants/values';
import { typedAppraisal } from 'utils';
import useMonkToken from '../../../modules/physical-eval-edit/components/PicturesForm/hooks/useMonkToken';
import useUploadMonkaiImage from './useUploadMonkaiImage';
import useStore from '../../pe-edit-appraisal.store';
import { useRef } from 'react';
import { IS_MONKAI_AVAILABLE } from '../../../constants/config/monkia.config';

const useUploadInspectionItem = () => {
  const [getAppraisal] = useGetAppraisalMutation();
  const [uploadAppraisalPhoto] = useUploadAppraisalPhotoReactiveMutation();
  const { removePhotos, setLoading } = useInspectionsStore();
  const [update] = useUpdateAppraisalMutation();
  const [startMonkaiInspection] = useStartMonkaiInspectionMutation();
  const { handleUploadMonkaiImage } = useUploadMonkaiImage();
  const { setAppraisalPhoto } = useStore();

  const { monkToken } = useMonkToken();
  const monkRef = useRef<string | null | undefined>(null);
  monkRef.current = monkToken;

  const uploadInspectionItem = async (currentInspection: InspectionItem) => {
    const { inspectionId, photos, appraisalId, isStartedInspection } =
      currentInspection;

    try {
      setLoading(appraisalId, inspectionId, true);

      if (photos.length) {
        const { data } = await getAppraisal({
          variables: { id: appraisalId.toString() },
        });

        if (!data?.getAppraisal) {
          setLoading(appraisalId, inspectionId, false);
          throw new Error('Unable to fetch appraisal');
        }

        for (const photo of photos) {
          const isPhotoStillExists = data.getAppraisal.result.photos.some(
            ({ src }) => src === photo.serverSrcId
          );

          if (isPhotoStillExists) {
            await new Promise<void>((resolve) => {
              const id = setInterval(() => {
                if (monkRef.current) {
                  clearInterval(id);
                  resolve();
                }
              }, 500);
            });

            if (IS_MONKAI_AVAILABLE) {
              try {
                await handleUploadMonkaiImage(
                  inspectionId,
                  photo.picture,
                  photo.sight
                );
                // eslint-disable-next-line no-empty
              } catch (err) {}
            }

            const uploadedPhoto = await uploadAppraisalPhoto({
              variables: {
                id: appraisalId,
                file: {
                  id: appraisalId,
                  src: photo.src,
                  zone: sights[photo.sight],
                },
              },
            });

            const srcS3 =
              uploadedPhoto.data?.uploadAppraisalPhotoReactive.result.src || '';

            const updatedPhotos = data.getAppraisal.result.photos.map(
              (photoItem) =>
                photoItem.src === photo.serverSrcId
                  ? { ...photoItem, src: srcS3 }
                  : photoItem
            );

            const jsonString = JSON.stringify(
              typedAppraisal({
                ...data.getAppraisal.result,
                photos: updatedPhotos,
              })
            );

            await update({ variables: { input: { jsonString } } });
            data.getAppraisal.result.photos = updatedPhotos;

            setAppraisalPhoto({
              id: appraisalId,
              zone: sights[photo.sight],
              src: srcS3,
            });

            removePhotos(appraisalId, inspectionId, [photo.serverSrcId]);
          } else {
            removePhotos(appraisalId, inspectionId, [photo.serverSrcId]);
          }
        }
      }

      if (isStartedInspection) {
        await startMonkaiInspection({
          variables: {
            input: {
              vehId: Number(appraisalId),
              monkaiInspectionId: inspectionId,
            },
          },
        });
      }
    } catch (err) {
      throw Error('error');
    } finally {
      setLoading(appraisalId, inspectionId, false);
    }
  };

  return { uploadInspectionItem };
};

export default useUploadInspectionItem;
