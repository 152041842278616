import React, { useCallback, useRef, useState } from 'react';
import { Capture, Controls, useSights, useUploads } from '@monkvision/camera';
import { Box } from '@mui/material';

import { useStyles } from './styles';
import { useMonitoring } from '@monkvision/corejs';
import { blobToBase64Jpg } from '../../../../utils/blob-to-base64-jpg';
import {
  initialColors,
  IS_MONKAI_AVAILABLE,
} from '../../../../constants/config/monkia.config';
import useAddPictureHandler from '../../../physical-eval-edit/components/PicturesForm/hooks/useAddPictureHandler';
import { useUploadImageAbility } from '../../../../contexts/OnlineStatusProvider/OnlineStatusProvider';
import { useInspectionsStore } from '../../../../store/offline-changes/inspections/inspections.store';
import useStore from 'store/pe-edit-appraisal.store';
import { v4 as uuidv4 } from 'uuid';

export type TakePictureArg = {
  picture: string;
  sight: string;
};

type ImageLoadedType = {
  isZoomedPicture: boolean;
  picture: {
    canvasHeight: number;
    canvasWidth: number;
    imageFilenameExtension: string;
    imageType: string;
    uri: string;
  };
  sight: string;
};

type CameraProps = {
  inspectionId: string;
  sightIds: string[];
  onClose: () => void;
  onTakePicture: (arg: TakePictureArg) => Promise<any>;
};

interface TakenPicture {
  uri: string;
}

type TakenPictures = Record<string, TakenPicture>;

const CameraWeb: React.FC<CameraProps> = ({
  inspectionId,
  sightIds,
  onClose,
  onTakePicture,
}) => {
  const sights = useSights({ sightIds });
  const uploads = useUploads({ sightIds });

  const { appraisal } = useStore();
  const { modelYear, make, model, id = 0 } = appraisal ?? {};

  const [loading, setLoading] = useState<boolean>(false);
  const classes = useStyles();
  const isAbleUploadImage = useUploadImageAbility();

  const { saveInspection } = useInspectionsStore();
  const { errorHandler } = useMonitoring();

  const uploadedRef = useRef(new Map<string, true>());

  const handleChange = useCallback(
    async (state: any) => {
      const END_ENDPOINT = 'qhKA2z';

      try {
        const { takenPictures, tour, current } = state.sights.state;

        const totalPictures = Object.keys(tour).length;
        const hasAllPictures =
          Object.keys(takenPictures).length === totalPictures;

        if (hasAllPictures && END_ENDPOINT === current?.id) {
          onClose();
        }

        if (hasAllPictures && totalPictures <= 1) {
          onClose();
        }
      } catch (err) {
        errorHandler(err);
        throw err;
      }
    },
    [errorHandler]
  );

  const onLoadImage = useCallback(async (pictureData: any) => {
    setLoading(true);
    try {
      const {
        picture: { uri, canvasWidth, canvasHeight },
        sight,
      } = pictureData;

      const base64 = await blobToBase64Jpg(uri, canvasWidth, canvasHeight);

      if (!isAbleUploadImage && IS_MONKAI_AVAILABLE) {
        const serverSrcId = uuidv4();

        await onTakePicture({
          picture: serverSrcId,
          sight,
        });

        saveInspection({
          appraisalId: id,
          inspectionId,
          carName: `${modelYear || ''} ${make || ''} ${model || ''}`,
          photos: [
            { src: base64, sight, serverSrcId, picture: pictureData.picture },
          ],
        });

        return;
      }

      await onTakePicture({
        picture: base64,
        sight,
      });
    } catch (error) {
      errorHandler(error);
      console.log('errorHandler MONK', error);
    } finally {
      setLoading(false);
    }
  }, []);

  const { handlePress } = useAddPictureHandler({
    sightIds,
    sights,
    uploads,
    onPicture: onLoadImage,
  });

  const controls = [
    [
      {
        disabled: loading,
        ...Controls.AddDamageButtonProps,
      },
      {
        disabled: loading,
        onPress: handlePress,
        ...Controls.CaptureButtonProps,
      },
    ],
    {
      disabled: loading,
      onPress: () => onClose(),
      ...Controls.GoBackButtonProps,
    },
  ];

  return (
    <Box className={classes.root}>
      <Capture
        inspectionId={inspectionId}
        controls={controls}
        loading={loading}
        enableComplianceCheck={false}
        sightIds={sightIds}
        sights={sights}
        uploads={uploads}
        primaryColor={process.env.REACT_APP_PRIMARY_COLOR_DARK}
        onReady={() => setLoading(false)}
        colors={{ ...initialColors }}
        settings={{ resolution: 'QHD' }}
        onChange={handleChange}
      />
    </Box>
  );
};

export default CameraWeb;
